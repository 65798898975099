<template>
    <v-dialog v-model="dialog" persistent scrollable width="400">
        <v-card>
            <v-card-title>
                <span class="headline">Fechar Agência</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="form1" v-model="form1Valid" lazy-validation>
                    <span>Selecione a agência que integrará essa</span>
                    <v-row>
                        <v-col>
                            <v-autocomplete v-model="agenciaId" label="Agência" placeholder=" "
                                            :items="agencias" :loading="isAgenciasLoading"
                                            :search-input.sync="searchAgenciasValue"
                                            item-text="nome" item-value="id" auto-select-first
                                            hide-no-data :rules="requiredRules" eager>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn  text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn
                               color="primary" text depressed @click="fecharAgencia" >
                            Finalizar
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {AgenciaQueries} from "@/queries/AgenciaQueries";
import gql from "graphql-tag";

export default {
    name: "FecharAgenciaDialog",
    data() {
        return {
            dialog: false,
            loading: false,
            form1Valid: true,
            isAgenciasLoading: false,
            searchAgenciasValue: null,
            agencias: [],
            agenciaId: null,
            requiredRules: [
                (v) => !!v || 'Campo obrigatório',
            ],
        }
    },
    watch: {
        searchAgenciasValue(value) {
            if (value !== null) {
                this.searchAgencias(value);
            }
        },
    },
    methods: {
        openDialog() {
            this.dialog = true;
            this.agencias = [];
        },
        closeDialog() {
            this.dialog = false;
        },
        searchAgencias(value) {
            this.isAgenciasLoading = true;
            this.$apollo.query({
                query: AgenciaQueries.findAgencias,
                variables: {arg: value, limit: 10},
            }).then((result) => {
                this.agencias = result.data.agencias.map((agencia) => {
                    agencia.nome = agencia.codigo + ' - ' + agencia.nome;

                    return agencia;
                });
            }).finally(() => {
                this.isAgenciasLoading = false;
            });
        },
        fecharAgencia(){
            if(!this.$refs.form1.validate()){
                return false
            }

            this.loading = true;
            this.$apollo.mutate({
                mutation: gql`mutation ($id:Int!, $integradaPor:Int){
                        agencia:fecharAgencia(id: $id, integradaPor: $integradaPor){
                            id
                        }
                    }`,
                variables: {
                    id: this.$route.params.id,
                    integradaPor: this.agenciaId,
                },
            }).then((result) => {
                this.$emit("finished", null);
                this.closeDialog();
            }).finally(() => {
                this.loading = false;
            })

        },
    }
}
</script>

<style scoped>

</style>