<template>
    <page v-if="agencia">
        <v-breadcrumbs slot="breadcrumb" class="pa-0">
            <v-breadcrumbs-item exact :to="{name:'dashboard'}">Início</v-breadcrumbs-item>
            <v-breadcrumbs-divider>></v-breadcrumbs-divider>
            <v-breadcrumbs-item exact :to="{name:'agencias'}">Agências</v-breadcrumbs-item>
        </v-breadcrumbs>
        <v-alert v-if="agencia.fechadaEm" class="py-1" dark color="red" >
            <span class="caption font-weight-regular">Agência fechada em
                <span class="caption font-weight-bold" >
                    {{agencia.fechadaEm | moment('DD [de] MMMM [de] YYYY')}}
                </span>
                por <span class="caption font-weight-bold" >{{agencia.fechadaPor.nome}}</span> e integrada na agência
                <span class="caption font-weight-bold" >
                    <router-link class="white--text" :to="{name: 'agencia_view', params: {id:agencia.integradaEm.id}}">
                        {{agencia.integradaEm.codigo}}
                    </router-link>
                </span>
            </span>
        </v-alert>

        <div>
            <span class="title font-weight-regular d-inline-flex" style="line-height: normal; align-items: center">
                {{agencia.codigo}} - {{agencia.nome}}
                <v-menu offset-y v-if="!agencia.fechadaEm" >
                    <template v-slot:activator="{ on }">
                        <v-btn class="ml-2" small icon color="grey" outlined v-on="on">
                            <v-icon small>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="fecharAgencia">
                            <v-list-item-title class="red--text">Fechar Agência</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </span>
        </div>
        <span class="caption text-uppercase">{{agencia.cnpj}}</span>
        <v-chip v-if="agencia.centralNf" x-small label color="blue" text-color="white" class="ml-1 font-weight-bold px-2">
            <span>Centralizadora</span>
        </v-chip>

        <v-card outlined class="mt-6 mb-6">

            <v-card-text>
                <v-row dense>
                    <v-col cols="12" md="8" class="pt-0">
                        <div style="background-color: whitesmoke">
                            <v-subheader class="text-uppercase caption flex" style="align-items: center">
                                <span class="blue-grey--text mr-2">LOCALIZAÇÃO</span>
                                <v-btn rounded depressed x-small color="primary" @click="editAgencia">Editar</v-btn>
                            </v-subheader>
                            <v-container class="py-0">
                                <v-row>
                                    <v-col class="pt-0" cols="12" sm="7">
                                        <v-row no-gutters>
                                            <v-col cols="8">
                                                <span class="label">Endereço</span>
                                                {{agencia.endereco}}
                                            </v-col>
                                            <v-col >
                                                <span class="label">Número</span>
                                                {{agencia.numero}}
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col cols="8">
                                                <span class="label">Complemento</span>
                                                {{agencia.complemento}}
                                            </v-col>
                                            <v-col>
                                                <span class="label">Bairro</span>
                                                {{agencia.bairro}}
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col cols="8">
                                                <span class="label">Cidade</span>
                                                <router-link :to="{ name: 'cidade_view', params: { id: parseInt(agencia.cidade.id) }}">
                                                    {{agencia.cidade.nome}}
                                                </router-link>
                                            </v-col>
                                            <v-col >
                                                <span class="label">UF</span>
                                                {{agencia.cidade.estado.sigla}}
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col >
                                                <span class="label">CEP</span>
                                                {{agencia.cep}}
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" sm="5" class="pt-0">
                                        <a target="_blank" :href="getMapLink(montaEndereco(agencia))">
                                            <v-img :src="getMapImageUrl(montaEndereco(agencia))"
                                                   width="100%"  class="fill-height" style="border: 1px solid #519657" >
                                                <template v-slot:placeholder>
                                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                                        <v-progress-circular indeterminate color="orange"></v-progress-circular>
                                                    </v-row>
                                                </template>
                                            </v-img>
                                        </a>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </div>
                    </v-col>
                    <v-col cols="12" md="4" class="pt-0">
<!--                        <v-subheader class="text-uppercase caption" style="height: auto" >
                            <span class="blue-grey&#45;&#45;text mr-2">CONTATOS</span>
                        </v-subheader>-->
                        <v-tabs grow >
                            <v-tab>AGÊNCIA <v-chip v-if="agenciaContatosAgencia.length > 0" x-small label color="orange" text-color="white" class="px-1 ml-2" >{{agenciaContatosAgencia.length}}</v-chip> </v-tab>
                            <v-tab>FUNCIONÁRIOS <v-chip v-if="agenciaContatos.length > 0" x-small label color="orange" text-color="white" class="px-1 ml-2" >{{agenciaContatos.length}}</v-chip> </v-tab>
                            <v-tab-item :transition="false" :reverse-transition="false">
                                <div style="background-color: whitesmoke" class="px-2 py-2">
                                    <v-btn  depressed x-small color="primary" @click="newContatoAgenciaDialog">Novo Contato</v-btn>
                                </div>
                                <v-list  dense v-if="agenciaContatosAgencia.length > 0">
                                    <template v-for="(contato, index) in agenciaContatosAgencia">
                                        <v-list-item :key="index" >
                                            <v-list-item-content>
                                                <v-list-item-title>{{ contato.telefone }}</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action class="ma-0">
                                                <div>
                                                    <v-btn text icon color="green" :href="formatPhoneLink(contato.telefone)">
                                                        <v-icon small>mdi-phone</v-icon>
                                                    </v-btn>
                                                    <v-btn text icon color="grey" @click="editContatoAgencia(contato, index)">
                                                        <v-icon small>mdi-pencil</v-icon>
                                                    </v-btn>
                                                    <v-btn text icon color="red" @click="removeContatoAgencia(contato, index)">
                                                        <v-icon small>mdi-delete</v-icon>
                                                    </v-btn>
                                                </div>
                                            </v-list-item-action>
                                        </v-list-item>
                                        <v-divider />
                                    </template>
                                </v-list>
                            </v-tab-item>
                            <v-tab-item :transition="false" :reverse-transition="false">
                                <div style="background-color: whitesmoke" class="px-2 py-2">
                                    <v-btn  depressed x-small color="primary" @click="newContatoDialog">Novo Contato</v-btn>
                                </div>
                                <div style="max-height: 170px; overflow-y: auto">
                                    <v-list dense v-if="agenciaContatos.length > 0">
                                        <template v-for="(contato, index) in agenciaContatos">
                                            <v-list-item :key="index" >
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        {{contato.nome}}
                                                        <v-chip label x-small color="blue darken-2" dark class="ml-1 text-uppercase px-1" v-if="contato.funcao">
                                                            {{contato.funcao.nome}}
                                                        </v-chip>
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>{{contato.telefone}}</v-list-item-subtitle>
                                                    <v-list-item-subtitle>
                                                        {{contato.email}}
                                                        <v-icon v-if="contato.emailFiscalizacao" title="Email de solicitação" small color="green">mdi-check</v-icon>
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action class="ma-0">
                                                    <div>
                                                        <v-menu offset-y v-if="contato.telefone" >
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn v-on="on" text icon color="green" >
                                                                    <v-icon small>mdi-phone</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <v-list>
                                                                <v-list-item :href="formatPhoneLink(contato.telefone)">
                                                                    <v-list-item-title>Ligar</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item @mousedown.stop @touchstart.native.stop
                                                                             :href="'https://web.whatsapp.com/send?phone=55'+ parseNumero(contato.telefone)"
                                                                             target="_blank">
                                                                    <v-list-item-title>Conversar</v-list-item-title>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-menu>
                                                        <v-btn text icon color="grey" @click="editContato(contato, index)">
                                                            <v-icon small>mdi-pencil</v-icon>
                                                        </v-btn>
                                                        <v-btn text icon color="red" @click="removeContato(contato, index)">
                                                            <v-icon small>mdi-delete</v-icon>
                                                        </v-btn>
                                                    </div>
                                                </v-list-item-action>
                                            </v-list-item>
                                            <v-divider />
                                        </template>
                                    </v-list>
                                </div>
                            </v-tab-item>
                        </v-tabs>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card outlined class="mb-6" v-if="fiscalizacoes">
            <v-card-text class="pa-0">
                <v-tabs color="primary" >
                    <v-tab>FISCALIZAÇÕES <v-chip v-if="fiscalizacoesTotalCount > 0" x-small label color="orange" text-color="white" class="px-1 ml-2" >{{fiscalizacoesTotalCount}}</v-chip> </v-tab>
                    <v-tab>PROJETOS <v-chip v-if="projetosTotalCount > 0" x-small label color="orange" text-color="white" class="px-1 ml-2" >{{projetosTotalCount}}</v-chip> </v-tab>
                    <v-tab-item :transition="false"  :reverse-transition="false">
                        <div class="text-center py-2" v-if="fiscalizacoes">
                            <v-pagination v-model="fiscalizacoesPage" total-visible="8"
                                          :length="Math.ceil(fiscalizacoesTotalCount / 10)"
                                          @input="fiscalizacoesPageChanged"></v-pagination>
                        </div>
                        <!--<v-list class="py-0" :three-line="$vuetify.breakpoint.smAndUp" :two-line="$vuetify.breakpoint.xsOnly" v-if="fiscalizacoes && fiscalizacoes.length > 0">-->
                        <v-list class="mt-2 py-0" two-line v-if="fiscalizacoes && fiscalizacoes.length > 0">
                            <template v-for="(fiscalizacao, index) in fiscalizacoes">
                                <v-list-item :dense="$vuetify.breakpoint.xsOnly" :key="fiscalizacao.id" :class="getFinalizadoClass(fiscalizacao)"  :to="{ name: 'fiscalizacao_view', params: { id: parseInt(fiscalizacao.id) }}" >
                                    <v-list-item-avatar>
                                        <v-icon class="light-green lighten-3" v-text="getItemIcon(fiscalizacao.tipo)"></v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-if="!$vuetify.breakpoint.xsOnly" v-html="fiscalizacao.financiamento.mutuario"></v-list-item-title>
                                        <v-list-item-title v-else class="body-2" v-html="fiscalizacao.financiamento.mutuario"></v-list-item-title>
                                        <v-list-item-subtitle v-if="!$vuetify.breakpoint.xsOnly" v-html="'N. Solicitação: ' + fiscalizacao.numeroSolicitacao + '&emsp;Operação: ' + fiscalizacao.financiamento.operacao+'-'+fiscalizacao.financiamento.operacaoDigito" >
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-else class="caption" style="line-height: 10px" v-html="'N. Sol.: ' + fiscalizacao.numeroSolicitacao + '&emsp;Op.: ' + fiscalizacao.financiamento.operacao+'-'+fiscalizacao.financiamento.operacaoDigito" >
                                        </v-list-item-subtitle>
                                        <div v-if="false">
                                            <v-tooltip bottom v-if="fiscalizacao.emailEnviadoAt">
                                                <template v-slot:activator="{ on }">
                                                    <v-chip v-on="on" x-small label color="blue" text-color="white" class="mr-1 font-weight-bold px-2">
                                                        EMAIL ENVIADO
                                                    </v-chip>
                                                </template>
                                                <span>{{fiscalizacao.emailEnviadoAt | moment('DD MMM YYYY HH:mm [hrs]')}}</span>
                                            </v-tooltip>

                                            <v-chip v-if="fiscalizacao.fotos.length > 0" x-small label color="orange" text-color="white" class="mr-1 font-weight-bold px-2">
                                                <span>{{fiscalizacao.fotos.length}} {{fiscalizacao.fotos.length > 1 ? "FOTOS" : "FOTO"}}</span>
                                            </v-chip>

                                            <v-chip v-if="fiscalizacao.arquivos.length > 0" x-small label color="teal lighten-1" text-color="white" class="mr-1 font-weight-bold px-2">
                                                <span>{{fiscalizacao.arquivos.length}} {{fiscalizacao.arquivos.length > 1 ? "ANEXOS" : "ANEXO"}}</span>
                                            </v-chip>

                                            <v-chip v-if="fiscalizacao.contatos.length > 0" x-small label color="blue-grey darken-1" text-color="white" class="mr-1 font-weight-bold px-2">
                                                <span>{{fiscalizacao.contatos.length}} {{fiscalizacao.contatos.length > 1 ? "CONTATOS" : "CONTATO"}}</span>
                                            </v-chip>

                                        </div>
                                    </v-list-item-content>

                                    <v-list-item-action v-if="!$vuetify.breakpoint.xsOnly">
                                        <v-list-item-action-text >Data Limite: <b>{{new Date(fiscalizacao.dataLimite) | moment("DD MMM YYYY")}}</b></v-list-item-action-text>
                                        <v-list-item-action-text v-if="fiscalizacao.dataFiscalizacao">Data Fiscalização: <b>{{new Date(fiscalizacao.dataFiscalizacao) | moment("DD MMM YYYY")}}</b></v-list-item-action-text>
                                    </v-list-item-action>
                                    <div v-if="fiscalizacao.dataCancelamento" class="d-flex justify-center align-center"
                                         style="position: absolute; width: 100%; height: 100%; left: 0; top: 0; background: repeating-linear-gradient(45deg,transparent,transparent 30px,rgba(255,0,0,0.1) 1px, rgba(255,0,0,0.1) 60px
  )">
                                        <span class="text-h4 font-weight-black">CANCELADO</span>
                                    </div>
                                </v-list-item>

                                <v-divider ></v-divider>
                            </template>
                        </v-list>
                        <div v-else-if="loading === false && (fiscalizacoes === null || fiscalizacoes.length === 0)" class="text-center pa-6">
                            <h1 class="display-3 mb-4">Ops!</h1>
                            <span class="headline font-weight-light">Nenhuma fiscalização executada para essa agência!</span>
                        </div>
                        <div class="text-center my-2" v-if="fiscalizacoes">
                            <v-pagination v-model="fiscalizacoesPage" total-visible="8"
                                          :length="Math.ceil(fiscalizacoesTotalCount / 10)"
                                          @input="fiscalizacoesPageChanged"></v-pagination>
                        </div>
                    </v-tab-item>
                    <v-tab-item :transition="false"  :reverse-transition="false">
                        <div class="text-center py-2" v-if="projetos">
                            <v-pagination v-model="projetosPage" total-visible="8"
                                          :length="Math.ceil(projetosTotalCount / 10)"
                                          @input="projetosPageChanged"></v-pagination>
                        </div>

                        <v-list class="projetos py-0" two-line v-if="projetos && projetos.length > 0">
                            <template v-for="(projeto, index) in projetos">
                                <v-list-item :key="projeto.id" :to="{ name: 'projeto_view', params: { id: parseInt(projeto.id) }}" >
                                    <v-list-item-content>
                                        <v-list-item-title >{{projeto.cliente}}</v-list-item-title>
                                        <div>
                                            <v-chip x-small label color="orange" text-color="white" class="mr-1 font-weight-bold px-2">
                                                <span>{{projeto.tipo.nome}}</span>
                                            </v-chip>
                                        </div>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-list-item-action-text >Data Solicitação: <b>{{new Date(projeto.dataSolicitacao) | moment("DD MMM YYYY")}}</b></v-list-item-action-text>
                                        <v-list-item-action-text v-if="projeto.dataEnvio">Data Envio: <b>{{new Date(projeto.dataEnvio) | moment("DD MMM YYYY")}}</b></v-list-item-action-text>
                                    </v-list-item-action>

                                </v-list-item>

                                <v-divider ></v-divider>
                            </template>
                        </v-list>

                        <div v-else-if="loading === false && (projetos === null || projetos.length === 0)" class="text-center pa-6">
                            <h1 class="display-3 mb-4">Ops!</h1>
                            <span class="headline font-weight-light">Nenhum projeto executado para essa agência!</span>
                        </div>
                        <div class="text-center my-2" v-if="projetos">
                            <v-pagination v-model="projetosPage" total-visible="8"
                                          :length="Math.ceil(projetosTotalCount / 10)"
                                          @input="projetosPageChanged"></v-pagination>
                        </div>
                    </v-tab-item>
                </v-tabs>
                <v-overlay :value="loading" color="white" absolute opacity="0.8" z-index="1" />
                <v-progress-linear :active="loading" :indeterminate="loading"
                                   absolute top color="secondary" />
            </v-card-text>

        </v-card>
        <edit-agencia-endereco-dialog ref="editAgenciaDialog" @updated="onAgenciaUpdated" />
        <add-contato-dialog ref="addContatoDialog" @saved="onContatoSaved" @edited="onContatoEdited" />
        <add-contato-agencia-dialog ref="addContatoAgenciaDialog" @saved="onContatoAgenciaSaved" @edited="onContatoAgenciaEdited" />
        <fechar-agencia-dialog ref="fecharAgenciaDialog" @finished="onAgenciaFechada" />
    </page>
</template>

<script>
    // @ts-ignore
    import page from "../../../components/Page";
    import gql from 'graphql-tag';
    import {getFinalizadoClass, getIconByFiscalizacaoTipo} from "../../../assets/FiscaizacaoUtil";
    // @ts-ignore
    import EditAgenciaEnderecoDialog from "../../servico/fiscalizacao/servico/EditAgenciaEnderecoDialog";
    // @ts-ignore
    import AddContatoDialog from "./AddContatoDialog";
    // @ts-ignore
    import AddContatoAgenciaDialog from "./AddContatoAgenciaDialog";
    import FecharAgenciaDialog from "@/views/recursos/agencia/FecharAgenciaDialog";

    export default {
        name: "ViewAgencia",
        components:{
            FecharAgenciaDialog,
            AddContatoAgenciaDialog,
            AddContatoDialog,
            page,
            EditAgenciaEnderecoDialog
        },
        filters: {
            phone(phone){
                if(!phone.includes("-")){
                    if(phone.length === 8){
                        return phone.replace(/(\d{4})(\d{4})/, '$1-$2')
                    }
                    if(phone.length === 9){
                        return phone.replace(/(\d{5})(\d{4})/, '$1-$2')
                    }
                }
                return phone;
            }
        },
        data() {
            return {
                agencia: null,
                agenciaContatos: [],
                agenciaContatosAgencia: [],
                loading: false,
                fiscalizacoes: [],
                fiscalizacoesPage: 1,
                fiscalizacoesTotalCount: 0,
                projetos: [],
                projetosPage: 1,
                projetosTotalCount: 0,
            }
        },
        mounted() {
            this.getAgencia()
        },
        watch:{
            '$route.params.id'(id){
                //this.getAgencia();
                location.reload();
            }
        },
        methods:{
            fiscalizacoesPageChanged(value){
                this.getFiscalizacoesByAgencia(this.agencia.id, value)
            },
            projetosPageChanged(value){
                this.getProjetosByAgencia(this.agencia.id, value)
            },
            getAgencia() {
                const id = this.$route.params.id;
                this.$apollo.query({
                    query: gql`query($id: Int!) {
                        agencia(id: $id){
                            id
                            nome
                            codigo
                            cnpj
                            endereco
                            numero
                            complemento
                            bairro
                            cep
                            centralNf
                            emails{
                                id
                                endereco
                            }
                            cidade{
                                id
                                nome
                                estado{
                                    nome
                                    sigla
                                }
                            }
                            fechadaEm
                            fechadaPor{
                                nome
                            }
                            integradaEm{
                                id
                                codigo
                                nome
                            }
                        }
                    }`,
                    variables: {id},
                }).then((result) => {
                    this.agencia = result.data.agencia;
                    document.title = this.agencia.codigo + ' - ' + this.agencia.nome + " - JF Assessoria Rural"
                    this.getFiscalizacoesByAgencia(this.agencia.id, this.fiscalizacoesPage)
                    this.getProjetosByAgencia(this.agencia.id, this.fiscalizacoesPage)
                    this.getAgenciaContatos(this.agencia.id)
                });
            },
            getAgenciaContatos(agenciaId){
                this.$apollo.query({
                    query: gql`query($id: Int!) {
                        agenciaContatos(id: $id){
                            id
                            nome
                            funcao{
                                id
                                nome
                            }
                            email
                            emailFiscalizacao
                            telefone
                            pin
                        }
                        agenciaContatosAgencia(id: $id){
                            id
                            telefone
                        }
                    }`,
                    variables: {
                        id: agenciaId
                    },
                }).then((result) => {
                    this.agenciaContatos = result.data.agenciaContatos;
                    this.agenciaContatosAgencia = result.data.agenciaContatosAgencia;
                });
            },
            getFiscalizacoesByAgencia(agenciaId, page){
                this.loading = true;
                this.$apollo.query({
                    query: gql`query($id: Int!, $page: Int, $offset: Int) {
                        fiscalizacoesPorAgencia(id: $id, page: $page, offset: $offset){
                            totalCount
                            fiscalizacoes:itens{
                                id
                                numeroSolicitacao
                                dataLimite
                                status
                                regular
                                dataFiscalizacao
                                tipo{
                                    nome
                                }
                                financiamento{
                                    operacao
                                    operacaoDigito
                                    mutuario
                                }
                                dataCancelamento
                            }
                        }
                    }`,
                    variables: {
                        id: agenciaId,
                        page: page -1,
                        offset: 10
                    },
                }).then((result) => {
                    this.fiscalizacoes = result.data.fiscalizacoesPorAgencia.fiscalizacoes;
                    this.fiscalizacoesTotalCount = result.data.fiscalizacoesPorAgencia.totalCount;
                    this.loading = false;
                    //this.$set(this.agencia, 'fiscalizacoes', result.data.agencia.fiscalizacoes)
                });
            },
            getProjetosByAgencia(agenciaId, page){
                this.loading = true;
                this.$apollo.query({
                    query: gql`query($id: Int!, $page: Int, $offset: Int) {
                        projetosPorAgencia(id: $id, page: $page, offset: $offset){
                            totalCount
                            projetos:itens{
                                id
                                cliente
                                dataSolicitacao
                                dataEnvio
                                tipo{
                                    nome
                                }
                            }
                        }
                    }`,
                    variables: {
                        id: agenciaId,
                        page: page -1,
                        offset: 10
                    },
                }).then((result) => {
                    this.projetos = result.data.projetosPorAgencia.projetos;
                    this.projetosTotalCount = result.data.projetosPorAgencia.totalCount;
                    this.loading = false;
                    //this.$set(this.agencia, 'fiscalizacoes', result.data.agencia.fiscalizacoes)
                });
            },
            getItemIcon(tipo){
                return getIconByFiscalizacaoTipo(tipo);
            },
            getFinalizadoClass(fiscalizacao){
                return getFinalizadoClass(fiscalizacao);
            },
            editAgencia(){
                this.$refs.editAgenciaDialog.openDialog(this.agencia)
            },
            onAgenciaUpdated(agencia){
                //this.agencia = Object.assign(this.agencia, agencia);
                this.agencia.endereco = agencia.endereco;
                this.agencia.numero = agencia.numero;
                this.agencia.bairro = agencia.bairro;
                this.agencia.complemento = agencia.complemento;
                this.agencia.cep = agencia.cep;
            },
            newContatoAgenciaDialog(){
                this.$refs.addContatoAgenciaDialog.openDialog(this.agencia);
            },
            editContatoAgencia(contato, index) {
                contato.index = index;
                this.$refs.addContatoAgenciaDialog.openDialog(this.agencia, contato);
            },
            onContatoAgenciaSaved(contato){
                this.agenciaContatosAgencia.push(contato);
            },
            onContatoAgenciaEdited(contato, index){
                let contatoExisted = this.agenciaContatosAgencia[index];
                contatoExisted = Object.assign(contatoExisted, contato);
            },
            removeContatoAgencia(contato, index) {
                this.$dialog.confirm({
                    text: 'Deseja realmente apagar este contato?',
                    title: 'Atenção',
                }).then((result) => {
                    if (result) {
                        this.$apollo.mutate({
                            mutation: gql`mutation ($id:Int!){
                                removeAgenciaContato(id: $id)
                            }`,
                            variables: {id: contato.id},
                        }).then(() => {
                            this.agenciaContatosAgencia.splice(index, 1);
                        });
                    }
                });
            },
            newContatoDialog(){
                this.$refs.addContatoDialog.openDialog(this.agencia);
            },
            editContato(contato, index) {
                contato.index = index;
                this.$refs.addContatoDialog.openDialog(this.agencia, contato);
            },
            onContatoSaved(contato){
                this.agenciaContatos.push(contato);
            },
            onContatoEdited(contato, index){
                let contatoExisted = this.agenciaContatos[index];
                contatoExisted = Object.assign(contatoExisted, contato);
            },
            removeContato(contato, index) {
                this.$dialog.confirm({
                    text: 'Deseja realmente apagar este contato?',
                    title: 'Atenção',
                }).then((result) => {
                    if (result) {
                        this.$apollo.mutate({
                            mutation: gql`mutation ($id:Int!){
                                removeAgenciaContato(id: $id)
                            }`,
                            variables: {id: contato.id},
                        }).then(() => {
                            this.agenciaContatos.splice(index, 1);
                        });
                    }
                });
            },
            fecharAgencia(){
                this.$apollo.query({
                    query: gql`query($agenciaId: Int!){
                        resultado:agenciaTemFiscalizacoesPendentes(agenciaId: $agenciaId)
                    }`,
                    variables: {
                        agenciaId: this.$route.params.id,
                    }
                }).then((result) => {
                    if(result.data.resultado === true){
                        this.$dialog.error({
                            title:'Ops!',
                            text: 'Existe fiscalizações pendentes para essa agência. Altere a agência dessas fiscalizações para outra para poder continuar com o fechamento desta',
                            actions:{ok:'ok'}})
                    }else{
                        this.$refs.fecharAgenciaDialog.openDialog()
                    }
                })
            },
            onAgenciaFechada(){
                this.getAgencia();
            },
            montaEndereco(agencia){
                let endereco = agencia.endereco.replace(/\s/g, '+')
                if(agencia.numero){
                    endereco += ',' + agencia.numero.replace(/\s/g, '+');
                }
                if(agencia.bairro){
                    endereco += ',' + agencia.bairro.replace(/\s/g, '+');
                }
                endereco += ',' + agencia.cidade.nome.replace(/\s/g, '+');
                endereco += ',' + agencia.cidade.estado.sigla.replace(/\s/g, '+');
                return endereco
            },
            getMapImageUrl(endereco){
                let url = 'https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyDVmydqrR66sMvCjiFRy9yTXo3KMzJuQIw'
                url += '&zoom=17&size=420x250&maptype=roadmap'
                //url += '&center='+endereco
                url += '&markers=size:mid%7C'+endereco
                //url += '&center=Rua+das+pereias,515,sinop,mt'
                return url;
            },
            getMapLink(endereco){
                return 'https://maps.google.com/?q=' + endereco;
            },
            formatPhoneLink(number){
                return 'tel:' + '021' + this.parseNumero(number);
            },
            parseNumero(numero){
                return numero.replace(/[^\d]/g, '');
            },
        }
    }
</script>

<style scoped>
    .label{
        color: #616161 !important;
        caret-color: #616161 !important;

        font-size: 0.75rem !important;
        font-weight: 400;
        letter-spacing: 0.0333333333em !important;
        line-height: 1.25rem;

        display: block !important;
    }
    .v-list-item-finalizado{
        background-color: #f9f9f9;
    }
    .v-list-item-finalizado .v-list-item__title,
    .v-list-item-finalizado .v-list-item__subtitle,
    .v-list-item-finalizado .v-list-item__action-text{
        opacity: 0.7;
    }
    .v-list-item-finalizado::after{
        width: 6px;
        content: "";
        display: inline-block;
        height: 72px; /*92 para 3 linhas*/
        margin-right: -16px;
        margin-left: 16px;
    }
    .finalizado-regular::after{
        background-color: #4CAF50;
    }
    .finalizado-irregular::after{
        background-color: #f31b1b;
    }
</style>