<template>
    <v-dialog v-model="dialog" width="400">
        <v-card>
            <v-card-title>
                <span class="headline">Nova Função</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="formFuncao" v-model="formFuncao" lazy-validation>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="nome" label="Nome"
                                          :rules="requiredRules" required>

                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="cancel">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn text color="primary" @click="save">Salvar</v-btn>
                    </div>

                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {ObjetivoFinanciadoQueries} from "../../../queries/ObjetivoFinanciadoQueries";
import gql from "graphql-tag";

export default {
    name: "AddContatoFuncaoDialog",
    data(){
        return{
            dialog: false,
            nome: null,
            formFuncao: true,
            requiredRules: [
                (v) => !!v || 'Campo obrigatório',
            ],
        }
    },
    methods:{
        openDialog() {
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
            this.nome = null;
        },
        save(){
            if (this.$refs.formFuncao.validate()) {
                /*const objetoParsed = this._.cloneDeep(this.objeto);
                objetoParsed.valor = this.convertMoneyToFloat(objetoParsed.valor);
                objetoParsed.quantidade = this.convertMoneyToFloat(objetoParsed.quantidade);
                delete objetoParsed.index;

                if(objetoParsed.qualificacao === null){
                    delete objetoParsed.qualificacao;
                }*/

                this.$apollo.mutate({
                    mutation: gql`mutation($nome: String!) {
                        saveContatoFuncao(nome: $nome){
                            id
                            nome
                        }
                    }`,
                    variables: {
                        nome: this.nome,
                    },

                }).then((result) => {
                    this.$emit('saved', Object.assign({}, result.data.saveContatoFuncao));
                    this.nome = null;
                    this.closeDialog();
                });
            }
        },
        cancel(){
            this.nome = null;
            this.closeDialog();
        }
    }
}
</script>

<style scoped>

</style>