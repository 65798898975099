<template>
    <v-dialog v-model="dialog" persistent max-width="500px" scrollable>
        <v-card v-if="dialog">
            <v-card-title>
                <span class="headline">Contato</span>
            </v-card-title>
            <v-card-text class="pb-0">
                <v-form ref="form1" v-model="form1Valid" lazy-validation>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field v-model="contato.nome" label="Nome"
                                          placeholder=" " :rules="requiredRules"
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-row no-gutters>
                                <v-col class="py-0">
                                    <v-autocomplete v-model="contato.funcao" label="Funcao"
                                                    placeholder=" " :items="funcoes"
                                                    item-text="nome" return-object clearable
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="2" class="py-0 align-self-center">
                                    <v-btn text icon color="primary" @click="newFuncao">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>

                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field v-model="contato.telefone" label="Telefone"
                                          maxlength="30" placeholder=" " clearable
                                          v-mask="['(##) ####-####', '(##) #####-####']"
                                          ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field v-model="contato.email" label="Email" clearable
                                          placeholder=" " :rules="emailRules"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-switch
                                v-model="contato.emailFiscalizacao"
                                label="Email para envio de solicitação">

                            </v-switch>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="cancel">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn color="primary" text @click="save">Salvar</v-btn>
                    </div>

                </div>
            </v-card-actions>
        </v-card>
        <add-contato-funcao-dialog ref="addContatoFuncaoDialog" @saved="onFuncaoSaved" />
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
// @ts-ignore
import AddContatoFuncaoDialog from "./AddContatoFuncaoDialog";

export default {
    name: "AddContatoDialog",
    components: {AddContatoFuncaoDialog},
    data() {
        return {
            dialog: false,
            agencia: null,
            funcoes: [],
            contato: {
                nome: null,
                funcao: null,
                telefone: null,
                email: null,
                emailFiscalizacao: false,
                telefoneAgencia: false,
            },
            loading: false,
            form1Valid: true,
            editMode: false,
            requiredRules: [
                (v) => !!v || 'Campo obrigatório',
            ],
            emailRules: [
                (v) => !v || /.+@.+\..+/.test(v) || 'E-mail deve ser válido',
            ],
        }
    },
    methods: {
        openDialog(agencia, contato) {
            this.dialog = true;
            this.agencia = agencia;
            if (contato) {
                this.editMode = true;
                this.contato = this._.cloneDeep(contato);
                this.contato.telefoneAgencia = false;
            }
            this.getFuncoes();
        },
        closeDialog() {
            this.dialog = false;
            this.editMode = false;
            this.contato = {
                nome: null,
                funcao: null,
                telefone: null,
                email: null,
                emailFiscalizacao: false,
                telefoneAgencia: false,
            }
        },
        getFuncoes(){
            this.$apollo.query({
                query: gql`query {
                    funcoes:contatoFuncoes{
                        id
                        nome
                    }
                }`
            }).then((result) => {
                this.funcoes = result.data.funcoes;
            })
        },
        newFuncao(){
            this.$refs.addContatoFuncaoDialog.openDialog();
        },
        onFuncaoSaved(funcao){
            this.funcoes.push(funcao);
            this.contato.funcao = funcao;
        },
        save() {
            if(!this.$refs.form1.validate()){
                return false
            }

            this.loading = true;
            if(!this.editMode){
                this.$apollo.mutate({
                    mutation: gql`mutation ($agenciaId: Int!, $contato:AgenciaContatoInput!){
                        saveAgenciaContato(agenciaId: $agenciaId, contato: $contato){
                            id
                            nome
                            funcao{
                                id
                                nome
                            }
                            email
                            emailFiscalizacao
                            telefone
                        }
                    }`,
                    variables:{
                        agenciaId: this.agencia.id,
                        contato: this.contato
                    }
                }).then((result) => {
                    this.$emit('saved', Object.assign({}, result.data.saveAgenciaContato));
                    this.closeDialog();
                })
            }else{
                let contatoId = this.contato.id;
                let index = this.contato.index;
                delete this.contato.index;
                delete this.contato.id;
                delete this.contato.pin;
                this.$apollo.mutate({
                    mutation: gql`mutation ($contatoId: Int!, $contato:AgenciaContatoInput!){
                        updateAgenciaContato(contatoId: $contatoId, contato: $contato){
                            id
                            nome
                            funcao{
                                id
                                nome
                            }
                            email
                            emailFiscalizacao
                            telefone
                        }
                    }`,
                    variables:{
                        contatoId,
                        contato: this.contato
                    }
                }).then((result) => {
                    this.$emit('edited', Object.assign({}, result.data.updateAgenciaContato), index);
                    this.closeDialog();
                })
            }
        },
        cancel() {
            this.closeDialog();
        },
    }
}
</script>

<style scoped>

</style>