<template>
    <v-dialog v-model="dialog" persistent max-width="400px">
        <v-card v-if="dialog">
            <v-card-title>
                <span class="headline">Contato</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="form1" v-model="form1Valid" lazy-validation>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field v-model="contato.telefone" label="Número"
                                          maxlength="30" placeholder=" " :rules="requiredRules" v-mask="['(##) ####-####', '(##) #####-####']"
                                          required></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn color="primary" text depressed @click="save" >
                            Salvar
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
    
</template>

<script>
    import gql from "graphql-tag";

    export default {
        name: "AddContatoAgenciaDialog",
        data() {
            return {
                dialog: false,
                agencia: null,
                contato: {
                    telefone: null,
                    telefoneAgencia: true,
                    emailFiscalizacao: false,
                },
                loading: false,
                form1Valid: true,
                editMode: false,
                requiredRules: [
                    (v) => !!v || 'Campo obrigatório',
                ],
            }
        },
        methods: {
            openDialog(agencia, contato) {
                this.dialog = true;
                this.agencia = agencia;
                if(contato){
                    this.editMode = true;
                    this.contato = this._.cloneDeep(contato);
                    this.contato.telefoneAgencia = true;
                    this.contato.emailFiscalizacao = false;
                }
            },
            closeDialog() {
                this.dialog = false;
                this.editMode = false;
                this.contato = {
                    telefone: null,
                    telefoneAgencia: true,
                    emailFiscalizacao: false,
                }
            },
            save(){
                if(!this.$refs.form1.validate()){
                    return false
                }
                this.loading = true;
                if(!this.editMode){
                    this.$apollo.mutate({
                        mutation: gql`mutation ($agenciaId: Int!, $contato:AgenciaContatoInput!){
                            saveAgenciaContato(agenciaId: $agenciaId, contato: $contato){
                                id
                                telefone
                            }
                        }`,
                        variables:{
                            agenciaId: this.agencia.id,
                            contato: this.contato
                        }
                    }).then((result) => {
                        this.$emit('saved', Object.assign({}, result.data.saveAgenciaContato));
                        this.closeDialog();
                    }).finally(()=>{
                        this.loading = false;
                    });
                }else{
                    let contatoId = this.contato.id;
                    let index = this.contato.index;
                    delete this.contato.index;
                    delete this.contato.id;
                    this.$apollo.mutate({
                        mutation: gql`mutation ($contatoId: Int!, $contato:AgenciaContatoInput!){
                            updateAgenciaContato(contatoId: $contatoId, contato: $contato){
                                id
                                telefone
                            }
                        }`,
                        variables:{
                            contatoId,
                            contato: this.contato
                        }
                    }).then((result) => {
                        this.$emit('edited', Object.assign({}, result.data.updateAgenciaContato), index);
                        this.closeDialog();
                    }).finally(()=>{
                        this.loading = false;
                    });
                }

            }
        }
    }
</script>

<style scoped>

</style>